import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";

const Footer = () => {
    return(
        <footer className="footer shadow p-b-5">
            <Container>
                <div className={"row my_social_links  d-flex justify-content-center align-items-center"}>
                    <div className={"w-25 d-flex justify-content-center"}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href={"https://github.com/Marioshkembi"} className={"col "}> <FontAwesomeIcon icon="fa-brands fa-github"/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href={"https://www.instagram.com/mario.skm/"} className={"col "}> <FontAwesomeIcon icon="fa-brands fa-linkedin-in"/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href={"https://www.linkedin.com/in/mario-shkembi-2054401ba/"} className={"col "}> <FontAwesomeIcon icon="fa-brands fa-instagram"/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href={"mailto:marioskm7@gmail.com"} title="marioskm7@gmail.com" className={"col "}><FontAwesomeIcon icon="fa-regular fa-envelope"/></a>
                </div>
                </div>
            </Container>

        </footer>
        );
}

export default Footer ;