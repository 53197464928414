import Container from "react-bootstrap/Container";


import movieapp from "../assets/images/movieapp.jpg"
import msgmate from "../assets/images/messagemate.png"
const ProjectsSection = () => {
    return(
        <section id="projects" className={"p-0 projects-section m-0"}>
        <Container className={"d-flex projects flex-column align-items-start justify-content-center inner_container mt-5 h-100"}>
            <h1 className={"section_header m-0 p-0 text-center text-white w-100"}>PROJECTS</h1>
            <div className={"d-flex m-0 flex-row p-0 justify-content-center w-100 mt-5"}>
                <div className="projects-wrapper">
                    <div className="row ">

                        <div className="project-card col">
                            <a href="https://msdb.marioshkembi.com/" target="_blank" rel="noreferrer" className=""
                               >
                                <img src={movieapp} className="card-img-top" alt="..." height="155px"/>
                                <div className="card-body p-5">
                                    <h5 className="cardbodytitle">Movie & Tv Show Search app</h5>
                                    <p className="card-text">Developed in React.js and used <a
                                        href="http://themoviedb.org">TMDb</a> API for the data,google api for the
                                        trailer,Swiper js for mobile friendly swiper,Bootstrap,React Router for the
                                        Routing.</p>

                                </div>
                            </a>
                        </div>


                        <div className="project-card col">
                            <img src={msgmate} className="card-img-top" alt="..." height="155px"/>
                            <div className="card-body p-5">
                                <h5 className="cardbodytitle">Message Mate</h5>
                                <p className="card-text">A realtime chat app for android build with Kotlin, Node.js ,JWT for security and
                                    MongoDB,and used FIRE BASE to add notifications system for messages</p>

                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </Container>
        </section>
    );
}

export default ProjectsSection;