import HEADER_COMPONENT from './components/header'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/main_style.css'
import Hero_section from "./components/hero_section";
import ExperienceSection from "./components/experience";
import ProjectsSection from "./components/projects_section";
import RecentBlogs from "./components/recent_blogs";
import Footer from "./components/footer";

function App() {
  return (
      <>
     <HEADER_COMPONENT></HEADER_COMPONENT>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
    <Hero_section></Hero_section>
          <ExperienceSection></ExperienceSection>
          <ProjectsSection></ProjectsSection>
          <RecentBlogs></RecentBlogs>
          <Footer></Footer>
      </>
  );
}

export default App;
