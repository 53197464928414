import Container from "react-bootstrap/Container";

import resume from "../assets/resume/marioshkembi.pdf"
const Hero_section = () => {
    return (
        <div className={"hero_section section"}>

            <Container className={"d-flex flex-column align-items-start justify-content-center inner_container h-100"}>

                <h1 className={"slide-right "}>Mario Shkembi</h1>
                <h2 className={"slide-right  animation_delay_03s"}>Full Stack software engineer</h2>
                <p className={"slide-right  animation_delay_04s"}>I develop web sites and mobile apps with perfection in mind</p>
                <a href={resume} target={"_blank"} className={"slide-right btn btn-lg btn-primary rounded-3 animation_delay_04s  resume_btn "}>RESUME</a>

            </Container>

        </div>
    )
}

export default Hero_section;