import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import HEADER_LINKS from "./header_links";
import {useEffect, useState} from "react";
import CloseButton from 'react-bootstrap/CloseButton';
const HEADER_COMPONENT = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (

    <header className={` text-white ${scrolled ? 'sticky-top sticky_header shadow' : ''}`}>

      <Container>
        <Row className="p-2 justify-content-between align-items-center">
          <Col xs lg="2">

            <a className="slide-right text-white text-decoration-none" href="/">
              Mario Shkembi
            </a>
          </Col>

          <Col xs lg="4">
            <Navbar
              expand="lg"
              className="d-none desktop_menu d-xl-flex d-xxl-flex d-lg-flex d-xl-flex justify-content-between"
            >
              <HEADER_LINKS></HEADER_LINKS>
            </Navbar>

            <Navbar
              expand="lg"
              className="d-none mobile_menu justify-content-end"
            >
              <Button
                className="d-lg-none hamburger"
                variant="primary"
                onClick={handleShow}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner text-white bg-white"></span>
                </span>
              </Button>

              <Offcanvas
                className="w-75 d-lg-none"
                show={show}
                onHide={handleClose}
              >

                <Offcanvas.Header>
                  <CloseButton className={"btn-close-white"} aria-label="close" onClick={handleClose}/>
                </Offcanvas.Header>
                <Offcanvas.Body className={"shadow"}>
                  <HEADER_LINKS></HEADER_LINKS>
                </Offcanvas.Body>
              </Offcanvas>

            </Navbar>

          </Col>
        </Row>
      </Container>

    </header>
  );
};

export default HEADER_COMPONENT;
