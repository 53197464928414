import Nav from "react-bootstrap/Nav";
const HEADER_LINKS = () => {
  return (
    <>
      <Nav.Link href="/">Home</Nav.Link>
      {/*<Nav.Link href="#about">About</Nav.Link>*/}
      <Nav.Link href="#experience">Experience</Nav.Link>
      <Nav.Link href="#projects">Projects</Nav.Link>
      <Nav.Link className="rounded-3 border blog_navbar pt-1 pb-1 pr-2 pl-2" href="/blog">Blog</Nav.Link>
    </>
  );
};

export default HEADER_LINKS;
