import Container from "react-bootstrap/Container";
const RecentBlogs = () => {
    return(
        <Container className={"d-flex flex-column align-items-start justify-content-center inner_container mt-5 h-100"}>
            <h1 className={"section_header text-center text-white w-100"}>RECENT BLOG POSTS</h1>
            <div className={"d-flex flex-row text-white justify-content-center w-100 mt-5"}>
                    COMMING SOON
            </div>

        </Container>
    );
}

export default RecentBlogs ;