import React from 'react';

const ExperienceReusableComponent = ({date, company, description, techStack}) => {
    return (
        <div className={"experience slide-left  p-4"}>
            <div className={'g-col-5'}>
                <p>{date}</p>
            </div>
            <div className={'g-col-5'}>
                <p className={"company_name"}>{company}</p>
                <p className={"job_description"}>{description}</p>
                    <div className={"tech_stack_wrapper d-flex gap-2 flex-wrap"}>
                    {techStack.map((str, index) => (
                        <span className={"p-1 rounded-pill tech_stack_item"}>{str}</span>
                    ))}
                    </div>
            </div>
        </div>
    );
};

export default ExperienceReusableComponent;
